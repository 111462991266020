import { redirectToPage } from './utils';

const COMPANY_PERMISSION_OPTIONS = {
  FORMULAS: 'formulator',
  FORMULA_SPECIFICATION: 'specification',
  FORMULA_NOTES: 'formula_note',
  FORMULA_STATUS: 'formula_status',
  FORMULA_LOCK: 'lock_formula',
  FORMULA_PHASE: 'phase',
  FORMULA_COST: 'cost',
  FUNCTION: 'function',
  PARTNERSIPS: 'partnerships',
  RM_CREATE_EDIT: 'compound_formulator',
  RM_STATUS: 'raw_material_status',
  RM_LOCK: 'lock_raw_material',
  QQ_XLS: 'generate_qq_xls_report',
  BENEFITS_XLS: 'benefits_report',
  REGULATORY_COMPLIANCE_XLS: 'regulatory_compliance_report',
  BATCH_CARD_XLS: 'generate_xls_batch_card_report',
  FORMULATED_WITHOUT_XLS: 'formulated_without_report',
  XLS_COMPOUND: 'generate_xls_compound_report',
  SCORECARD_PDF: 'generate_scorecard_report',
  FULL_PRODUCT_SCORECARD_PDF: 'generate_product_report',
  MATERIAL_DATASHEET_PDF: 'generate_material_datasheet_report',
  CORRECTIVE_ACTIONS_PDF: 'generate_corrective_actions_report',
  RAW_MATERIAL_PDF: 'generate_raw_material_report',
  RAW_MATERIAL_COMPLIANCE_PDF: 'generate_raw_material_compliance_report',
  SHOW_BENEFITS_BLOCK: 'show_benefits_block',
  CARA_ACCESS: 'cara_access',
  CARA_REVERSE_INGINEERING: 'cara_raw_material_combinator',
  CARA_PRODUCT_DOPELGANGERS: 'cara_product_dopelgangers',
  BLACKLIST: 'blacklist',
  PRODUCT_LISTING_SPL_GENERATOR: 'product_listing_spl_generator',
  ELF_CUSTOM_FORMULA_CARD: 'formula_card_report',
  ELF_CUSTOM_SPEC_SHEET: 'product_specs_report',
  TNL: 'inventory',
  IN_MARKET: 'consumer_dbs'
}

const USER_PERMISSION_OPTIONS = {
  FORMULA_CREATE_DUPLICATE: 'formula.create',
  FORMULA_EDIT_NOTES: 'formula_note.edit',
  FORMULA_DELETE: 'formula.delete',
  FORMULA_DELETE_OWN: 'formula.delete.own',
  RM_CREATE: 'raw_material.create',
  RM_EDIT: 'raw_material.edit',
  RM_DELETE: 'raw_material.delete',
  FORMULA_EDIT_SPECS: 'formula_specification.edit',
  PARTNERSSHIP_VIEW: 'partnerships.edit',
  XLS_QQ_REPORT: 'xls_qq_report.generate',
  XLS_BENEFITS_REPORT: 'xls_benefits_report.generate',
  XLS_COMPIANCE_REPORT: 'xls_compliance_report.generate',
  XLS_FORMULATED_WITHOUT_REPORT: 'xls_formulated_without_report.generate',
  XLS_RAW_MATERIAL_COMPLIANCE_REPORT: 'xls_raw_material_compliance_report.generate',
  XLS_BATCH_CARD_REPORT: 'xls_batch_card_report.generate',
  PDF_LIMITED_PRODUCT_SCORECARD_REPORT: 'pdf_product_scorecard_report.generate',
  PDF_MATERIAL_DATASHEET_REPORT: 'pdf_material_datasheet_report.generate',
  PDF_CORRECTIVE_ACTIONS_REPORT: 'pdf_corrective_actions_report.generate',
  PDF_FULL_PRODUCT_SCORECARD_REPORT: 'pdf_full_product_scorecard_report.generate',
  PDF_FULL_RM_REPORT: 'pdf_full_raw_material_report.generate',
  PDF_LIMITED_RM_REPORT: 'pdf_limited_raw_material_report.generate',
  PRIVATE_CHEMICAL_POLICE: 'private_chemical_police.edit',
  FORMULA_LOCK_UNLOCK: 'formula.lock',
  RM_LOCK_UNLOCK: 'raw_material.lock',
  INGREDIENT_SEARCH: 'ingredient.search',
  CENTRIC_SYNCHRONIZE_FORMULA: 'centric.synchronize_formula',
  CENTRIC_SYNCHRONIZE_RM: 'centric.synchronize_raw_material',
  ELF_CARD_REPORT: 'elf.card_report',
  ELF_SPEC_SHEET: 'elf.spec_sheet',
  RM_EDIT_STATUS: 'raw_material.status',
  REGULATORY_PRESETS_EDIT: 'regulatory_presets.edit',
  FORMULA_EDIT_STATUS: 'formula.status',
  CARA_PRODUCT_RATE_PREDICT: 'cara_product_rate_predict',
  CARA_REVERSE_INGINEERING: 'cara.reverse_engineering',
  CARA_PRODUCT_DOPELGANGERS: 'cara.product_dopelgangers',
  INMARKET_SPL: 'in_market.spl',
  FORMULAS: 'formulas.access',
  RM: 'raw_materials.access',
  PARTNERSHIPS: 'partnerships.access',
  IN_MARKET: 'in-market.access',
  REPORTS: 'reports.access',
  TNL: 'tnl.access'
}

export const FORMULAS = 'Formulas'
export const PARTNERSHIPS = 'Partnerships'
export const RAWMATERIALS = 'RawMaterials'
export const INMARKET = 'InMarket'
export const REPORTS = 'Reports'
export const TRADE_NAME_LIBRARY = 'Trade_Name_Library'
export const PRIVATE_CHEMICAL_POLICE = 'chemical police'
export const CARA = 'Cara'
export const CUSTOM_FEATURES = 'CustomFeatures'

export const checkPermissions = ({ userPermissions, companyPermissions, type }) => {
  let reportsDisabled = false;
  let USER_FORMULAS = false
  let companyFormulaFormulas = false
  let USER_RM = false
  let createEditRM = false
  let USER_PARTNERSHIPS = false
  let companyPartnerships = false
  let USER_TNL = false
  let companyTNLPermission = false
  let USER_INMARKET = false
  let companyInMarketPermission = false
  let BLACKLIST = false
  switch (type) {
    case FORMULAS:
      companyFormulaFormulas = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.FORMULAS)
      USER_FORMULAS = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.FORMULAS)
      break;
    case RAWMATERIALS:
      USER_RM = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.RM)
      createEditRM = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.RM_CREATE_EDIT)
      break;
    case PARTNERSHIPS:
      USER_PARTNERSHIPS = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.PARTNERSHIPS)
      companyPartnerships = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.PARTNERSIPS)
      break;
    case REPORTS: {
      const USER_XLS_QQ_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_QQ_REPORT);
      const USER_XLS_BENEFITS_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_BENEFITS_REPORT);
      const USER_XLS_COMPLIANCE_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_COMPIANCE_REPORT);
      const USER_XLS_FORMULATED_WITHOUT_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_FORMULATED_WITHOUT_REPORT);
      const USER_XLS_RAW_MATERIAL_COMPLIANCE_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_RAW_MATERIAL_COMPLIANCE_REPORT);
      const USER_ELF_CARD_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.ELF_CARD_REPORT);
      const USER_ELF_SPEC_SHEET_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.ELF_SPEC_SHEET);
      const USER_XLS_BATCH_CARD_REPORT = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.XLS_BATCH_CARD_REPORT)

      const COMPANY_XLS_QQ_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.QQ_XLS);
      const COMPANY_XLS_BENEFITS_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.BENEFITS_XLS);
      const COMPANY_XLS_COMPLIANCE_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.XLS_COMPOUND);
      const COMPANY_XLS_FORMULATED_WITHOUT_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.FORMULATED_WITHOUT_XLS);
      const COMPANY_XLS_BATCH_CARD_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.BATCH_CARD_XLS);
      const COMPANY_XLS_RAW_MATERIAL_COMPLIANCE_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.REGULATORY_COMPLIANCE_XLS);
      const COMPANY_ELF_CARD_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.ELF_CUSTOM_FORMULA_CARD);
      const COMPANY_ELF_SPEC_SHEET_REPORT = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.ELF_CUSTOM_SPEC_SHEET)
      const allUserELFReportDisabled = !USER_ELF_CARD_REPORT && !USER_ELF_SPEC_SHEET_REPORT
      const companyAllReportsDisabled =
        !COMPANY_XLS_QQ_REPORT &&
        !COMPANY_XLS_BENEFITS_REPORT &&
        !COMPANY_XLS_COMPLIANCE_REPORT &&
        !COMPANY_XLS_FORMULATED_WITHOUT_REPORT &&
        !COMPANY_XLS_BATCH_CARD_REPORT &&
        !COMPANY_XLS_RAW_MATERIAL_COMPLIANCE_REPORT
      const companyElfReportsDisabled = !COMPANY_ELF_CARD_REPORT && !COMPANY_ELF_SPEC_SHEET_REPORT
      const allUserReportPermissionsDisabled =
        !USER_XLS_QQ_REPORT &&
        !USER_XLS_BENEFITS_REPORT &&
        !USER_XLS_FORMULATED_WITHOUT_REPORT &&
        !USER_XLS_COMPLIANCE_REPORT &&
        !USER_XLS_RAW_MATERIAL_COMPLIANCE_REPORT &&
        !USER_XLS_BATCH_CARD_REPORT
      reportsDisabled = !(companyAllReportsDisabled && companyElfReportsDisabled) && !(allUserReportPermissionsDisabled && allUserELFReportDisabled);
      break;
    }
    case TRADE_NAME_LIBRARY:
        USER_TNL = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.TNL)
        companyTNLPermission = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.TNL)
      break
    case INMARKET:
      USER_INMARKET = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.IN_MARKET)
      companyInMarketPermission = !!companyPermissions?.find((item) => item.name === COMPANY_PERMISSION_OPTIONS.IN_MARKET)
      break;
    case PRIVATE_CHEMICAL_POLICE:
      BLACKLIST = !!userPermissions?.find((item) => item.name === USER_PERMISSION_OPTIONS.PRIVATE_CHEMICAL_POLICE)
      break
    default:
      break;
  }

  return {
    reportsDisabled,
    USER_FORMULAS,
    companyFormulaFormulas,
    USER_RM,
    createEditRM,
    USER_PARTNERSHIPS,
    companyPartnerships,
    USER_TNL,
    companyTNLPermission,
    USER_INMARKET,
    companyInMarketPermission,
    BLACKLIST,
  };
}

export const allPermissions = (companyPermissions, userPermissions) => {

    const SUPPLIER_BASIC = !!companyPermissions?.find((item) => item.name === 'supplier_basic_account');

    const { USER_FORMULAS, companyFormulaFormulas } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: FORMULAS,
    });

    const { USER_RM, createEditRM } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: RAWMATERIALS,
    });

    const { USER_PARTNERSHIPS, companyPartnerships } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: PARTNERSHIPS,
    });

    const { reportsDisabled } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: REPORTS,
    });

    const { USER_INMARKET, companyInMarketPermission } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: INMARKET,
    });

    const { USER_TNL, companyTNLPermission } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: TRADE_NAME_LIBRARY,
    });

    const { BLACKLIST } = checkPermissions({
      userPermissions,
      companyPermissions,
      type: PRIVATE_CHEMICAL_POLICE,
    });

    return {
      SUPPLIER_BASIC,
      USER_FORMULAS,
      companyFormulaFormulas,
      USER_RM,
      createEditRM,
      USER_PARTNERSHIPS,
      companyPartnerships,
      reportsDisabled,
      USER_INMARKET,
      companyInMarketPermission,
      USER_TNL,
      companyTNLPermission,
      BLACKLIST,
    };
  }

export const pageRedirect = ({
  companyPermissions,
  userPermissions,
  history,
  isFormulas = false,
  isRM = false,
  isPartners = false,
  isReports = false,
  isPolicies = false,
  isLibrary = false,
  isInMarket = false,
}) => {
   const {
    SUPPLIER_BASIC,
    USER_FORMULAS,
    companyFormulaFormulas,
    USER_RM,
    createEditRM,
    USER_PARTNERSHIPS,
    companyPartnerships,
    reportsDisabled,
    USER_INMARKET,
    companyInMarketPermission,
    USER_TNL,
    companyTNLPermission,
    BLACKLIST
  } = allPermissions(companyPermissions, userPermissions)

  if (companyFormulaFormulas &&
    USER_FORMULAS &&
    !SUPPLIER_BASIC && !isFormulas) {
    redirectToPage('/fa/formulas', history);
  } else if (createEditRM && USER_RM && !isRM) {
    redirectToPage('/fa/raw-material', history);
  } else if (USER_PARTNERSHIPS && companyPartnerships && !isPartners) {
    redirectToPage('/fa/partners', history);
  } else if (!reportsDisabled && !isReports) {
    redirectToPage('/fa/reports', history);
  } else if (BLACKLIST && !isPolicies) {
    redirectToPage('/fa/policies', history);
  } else if (USER_TNL && companyTNLPermission && !isLibrary) {
    redirectToPage('/fa/library', history);
  } else if (USER_INMARKET && companyInMarketPermission && !isInMarket) {
    redirectToPage('/fa/in-market-products', history);
  } else {
    redirectToPage('/fa/consumer-study', history);
  }
}
